<template>
  <div v-if="items">
    <v-row align="start">
      <v-col xs="12" sm="12" md="4">
        <v-card elevation="1" outlined>
          <v-toolbar dense>
            <v-icon
              :color="items.account.enabled ? 'blue' : 'grey'"
              class="mr-3"
              >mdi-account</v-icon
            >
            <v-toolbar-title>{{$i18n.t('$vuetify.site.account')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch class="mt-5" v-model="items.account.enabled"></v-switch>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="6"> {{$i18n.t('$vuetify.site.registration')}} </v-col>
              <v-col cols="6">
                <v-switch
                  class="mt-5 float-right mt-n2"
                  v-model="items.account.reg"
                ></v-switch>
              </v-col>
              <v-col cols="6" class="mt-n3"> {{$i18n.t('$vuetify.buttons.login')}} </v-col>
              <v-col cols="6" class="mt-n3">
                <v-switch
                  class="mt-5 float-right mt-n2"
                  v-model="items.account.login"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="4">
        <v-card elevation="1" outlined>
          <v-toolbar dense>
            <v-icon
              :color="items.facebook.enabled ? 'blue' : 'grey'"
              class="mr-3"
              >mdi-facebook</v-icon
            >
            <v-toolbar-title>Facebook</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch class="mt-5" v-model="items.facebook.enabled"></v-switch>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              label="Page url"
              v-model="items.facebook.pageUrl"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="4">
        <v-card elevation="1" outlined>
          <v-toolbar dense>
            <v-icon :color="items.youtube.enabled ? 'red' : 'grey'" class="mr-3"
              >mdi-youtube</v-icon
            >
            <v-toolbar-title>Youtube</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch class="mt-5" v-model="items.youtube.enabled"></v-switch>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              label="Page url"
              v-model="items.youtube.pageUrl"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="4">
        <v-card elevation="1" outlined>
          <v-toolbar dense>
            <v-icon :color="items.twitter.enabled ? 'blue' : 'grey'" class="mr-3"
              >mdi-twitter</v-icon
            >
            <v-toolbar-title>Twitter</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch class="mt-5" v-model="items.twitter.enabled"></v-switch>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              label="Page url"
              v-model="items.twitter.pageUrl"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="4">
        <v-card elevation="1" outlined>
          <v-toolbar dense>
            <v-icon
              :color="items.instagram.enabled ? 'blue' : 'grey'"
              class="mr-3"
              >mdi-instagram</v-icon
            >
            <v-toolbar-title>Instagram</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch class="mt-5" v-model="items.instagram.enabled"></v-switch>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              label="Page url"
              v-model="items.instagram.pageUrl"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="4">
        <v-card elevation="1" outlined>
          <v-toolbar dense>
            <v-icon
              :color="items.telegram.enabled ? 'blue' : 'grey'"
              class="mr-3"
              >mdi-telegram</v-icon
            >
            <v-toolbar-title>telegram</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch class="mt-5" v-model="items.telegram.enabled"></v-switch>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              label="Page url"
              v-model="items.telegram.pageUrl"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="4">
        <v-card elevation="1" outlined>
          <v-toolbar dense>
            <v-icon
              :color="items.linkedin.enabled ? 'blue' : 'grey'"
              class="mr-3"
              >mdi-linkedin</v-icon
            >
            <v-toolbar-title>Linkedin</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch class="mt-5" v-model="items.linkedin.enabled"></v-switch>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              label="Page url"
              v-model="items.linkedin.pageUrl"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="4">
        <v-card elevation="1" outlined>
          <v-toolbar dense>
            <v-icon
              :color="items.messenger.enabled ? 'blue' : 'grey'"
              class="mr-3"
              >mdi-facebook-messenger</v-icon
            >
            <v-toolbar-title>Messenger</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch class="mt-5" v-model="items.messenger.enabled"></v-switch>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              label="fb:app_id"
              v-model="items.messenger.appId"
            ></v-text-field>
            <v-text-field
              label="fb:page_id"
              v-model="items.messenger.pageId"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="save()">
        <v-icon class="mr-3">mdi-content-save</v-icon>
        {{ $vuetify.lang.t("$vuetify.action.save") }}
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import Api from "@/services/api";
export default {
  data() {
    return {
      items: false,
      messengerIcon: false,
      saveItemsLoading: false,
    };
  },
  created: function () {
    this.getData();
  },
  watch: {
    $route() {
      this.getData();
    },
    "items.account.enabled": function (state) {
      this.items.account.login = state;
      this.items.account.reg = state;
    },
    "items.account.login": function (state) {
      if (!state && !this.items.account.reg) {
        this.items.account.enabled = state;
      }
    },
    "items.account.reg": function (state) {
      if (!state && !this.items.account.login) {
        this.items.account.enabled = state;
      }
    },
  },
  methods: {
    getData() {
      Api.settings("get").then(({ data }) => {
        this.items = data;
        if (!this.items.account.enabled) {
          this.items.account.reg = false;
          this.items.account.login = false;
        }
      });
      this.saveItemsLoading = false;
    },
    save() {
      this.saveItemsLoading = true;

      if (this.items.facebook.enabled && !this.items.facebook.pageUrl) {
        this.items.facebook.enabled = false;
      }
      
      if (this.items.youtube.enabled && !this.items.youtube.pageUrl) {
        this.items.youtube.enabled = false;
      }
      
      if (this.items.twitter.enabled && !this.items.twitter.pageUrl) {
        this.items.twitter.enabled = false;
      }
      
      if (this.items.instagram.enabled && !this.items.instagram.pageUrl) {
        this.items.instagram.enabled = false;
      }
      
      if (this.items.telegram.enabled && !this.items.telegram.pageUrl) {
        this.items.telegram.enabled = false;
      }
      
      if (this.items.linkedin.enabled && !this.items.linkedin.pageUrl) {
        this.items.linkedin.enabled = false;
      }
      
      if (this.items.messenger.enabled && !this.items.messenger.appId || !this.items.messenger.pageId) {
        this.items.messenger.enabled = false;
      }

      Api.settings("set", this.items).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
        this.saveItemsLoading = false;
      });
    },
  },
};
</script>